@for (action of column.actions; track $index) {
<div class="align-self-center">
  @if (!action.menuItems && (!action.isVisible || action.isVisible(row))) {
  <button
    pButton
    *ngIf="action.label"
    [label]="action.label"
    class="p-button-outlined ml-2"
    (click)="onAction(row, action.action)"
  ></button>
  <i
    (click)="onAction(row, action.action)"
    [pTooltip]="action.tooltip || ''"
    [tooltipDisabled]="!action.tooltip"
    [class]="action.icon"
    *ngIf="action.icon"
    class="cursor-pointer mr-2"
  ></i>
  } @if (action.menuItems && (!action.isVisible || action.isVisible(row))) { @if
  (!isDisabledMenu(action)) {
  <fa-icon
    class="cursor-pointer text-primary pl-3"
    [icon]="faEllipsisV"
    (click)="menu.toggle($event)"
  ></fa-icon>
  <p-menu appendTo="body" #menu [popup]="true" [model]="menuItems"></p-menu>
  }
  <fa-icon
    *ngIf="isDisabledMenu(action)"
    class="text-gray pl-3"
    [icon]="faEllipsisV"
  ></fa-icon>
  }
</div>
}
