<!--<ms-loader *ngIf="loading"></ms-loader>-->
<p-table
  #table
  dataKey="id"
  [loading]="loading"
  [autoLayout]="autoLayout"
  [lazy]="lazy"
  (onLazyLoad)="update($event)"
  [value]="data?.items || []"
  [(selection)]="selectedItems"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnSelect($event)"
  [rowHover]="true"
  [rowTrackBy]="trackByFunction"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  [paginator]="paginator"
  [first]="0"
  [rows]="rows"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [scrollDirection]="'both'"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  (onSort)="sort($event)"
  [rowsPerPageOptions]="[10, 25, 50]"
  [totalRecords]="data?.totalItemCount || 0"
  [groupRowsBy]="groupRowsBy ? groupRowsBy : undefined"
  [styleClass]="isStickyHeader ? 'sticky-header' : styleClass"
  rowGroupMode="subheader"
  [filterDelay]="0"
  [virtualScroll]="virtualScroll"
  [virtualRowHeight]="virtualRowHeight"
  [expandedRowKeys]="expandedItems"
  (onRowExpand)="onRowExpand()"
  (onRowCollapse)="onRowCollapse()"
>
  <ng-container *ngIf="groupRowsBy">
    <ng-template pTemplate="groupheader" let-item>
      <tr pRowGroupHeader>
        <td
          [attr.colspan]="columns?.length || 0"
          class="ms-group-header w-full"
        >
          <span class="p-text-bold p-ml-2">{{ item[groupRowsBy] }}</span>
        </td>
      </tr>
    </ng-template>
  </ng-container>

  @if (showCaption) {
  <ng-template pTemplate="caption">
    <div class="flex align-items-center" *ngIf="selectedItems?.length">
      @if (!isSelectedOnAllPages) {
      <div>{{ selectedItems?.length }} selected</div>
      <button
        pButton
        type="button"
        icon="pi pi-times"
        (click)="unSelectAll()"
        class="ml-2 p-button-rounded p-button-text"
      ></button>
      } @for (action of multipleActions; track $index) {
      <!--          TODO: cleanup-->
      <button
        pButton
        *ngIf="
          action.label &&
          action.action &&
          isShowMultipleAction(action) &&
          (action.visibleInHeader === undefined || action.visibleInHeader)
        "
        [label]="action.label || ''"
        class="ml-3 p-button-outlined"
        (click)="onMultipleAction(action.action)"
      ></button>
      }
      <div
        *ngIf="
          data &&
          data.totalItemCount &&
          allSelected &&
          paginator &&
          (selectedItems.length < data.totalItemCount || isSelectedOnAllPages)
        "
        class="flex ml-8 align-items-center"
      >
        <button
          pButton
          [label]="
            isSelectedOnAllPages
              ? 'Unselect All'
              : 'Select All ' + data?.totalItemCount + ' items'
          "
          class="p-button-text"
          (click)="selectOnAllPages()"
        ></button>
        <div *ngIf="isSelectedOnAllPages" class="ml-3">
          All {{ data?.totalItemCount }} items are selected.
        </div>
      </div>
    </div>
  </ng-template>
  }

  <ng-template pTemplate="header" *ngIf="showHeader">
    <tr>
      @for (column of columns; track column.key) {
      <th
        *ngIf="!column.hidden"
        pFrozenColumn
        [frozen]="column.frozen"
        [alignFrozen]="column.alignFrozen"
        [pSortableColumn]="column.sortKey"
        [pSortableColumnDisabled]="!column.sortable"
        [class]="column.getColumnClass('header-th')"
      >
        <span
          *ngIf="column.tooltip"
          [pTooltip]="column.tooltip"
          [showDelay]="tooltipShowDelay"
          tooltipPosition="bottom"
        >
          {{ column.label }}
          <ms-sortIcon
            class="ml-2"
            field="{{ column.sortKey }}"
            *ngIf="column.sortable"
          ></ms-sortIcon>
        </span>
        <span *ngIf="!column.tooltip && column.label">
          {{ column.label }}
          <ms-sortIcon
            class="ml-2"
            field="{{ column.sortKey }}"
            *ngIf="column.sortable"
          ></ms-sortIcon>
        </span>

        <button
          type="button"
          pButton
          *ngIf="column.isExpand && expandableProperty"
          class="p-button-text p-button-rounded p-button-plain"
          icon="true"
          (click)="toggleExpandAll()"
        >
          <fa-icon
            [icon]="allExpanded ? faAngleDown : faAngleRight"
            class="text-primary"
          ></fa-icon>
        </button>

        <p-triStateCheckbox
          name="selectAll"
          (onChange)="selectAll($event, column)"
          [(ngModel)]="allSelected"
          *ngIf="column.isSelect"
          [disabled]="isSelectionDisabled()"
        ></p-triStateCheckbox>
      </th>
      }
    </tr>
  </ng-template>

  <ng-template
    pTemplate="body"
    let-rowData
    let-rowIndex="rowIndex"
    let-expanded="expanded"
  >
    <tr *ngIf="!loading" [ngClass]="{ 'p-expanded-row': expanded }">
      @for (column of columns; track column.key) {
      <td
        *ngIf="!column.hidden"
        pFrozenColumn
        [frozen]="column.frozen || false"
        [alignFrozen]="column.alignFrozen"
        [class]="getColumnClass(column.key, 'body-td')"
      >
        <ng-container
          *ngIf="column.isData"
          [ngTemplateOutlet]="getTemplate(column) || defaultCellTemplate"
          [ngTemplateOutletContext]="{
            $implicit: rowData,
            rowData: rowData,
            column: column,
            value: getPropertyByPath(rowData, column.key)
          }"
        >
        </ng-container>

        @if (column.isExpand) {
        <button
          *ngIf="isExpandable(rowData)"
          type="button"
          pButton
          [pRowToggler]="rowData"
          class="p-button-text p-button-rounded p-button-plain"
          icon="true"
        >
          <fa-icon
            [icon]="expanded ? faAngleDown : faAngleRight"
            class="text-primary"
          ></fa-icon>
        </button>
        }

        <p-tableCheckbox
          [inputId]="rowData.id"
          *ngIf="column.isSelect"
          [value]="rowData"
          [disabled]="isSelectionDisabled(column, rowData)"
        ></p-tableCheckbox>

        <div
          *ngIf="column.isActions(rowData)"
          class="flex w-full justify-content-center"
        >
          <ms-action-column
            [column]="column"
            [row]="rowData"
            (action)="onAction($event)"
          >
          </ms-action-column>
        </div>
      </td>
      }
    </tr>
    @if (loading) {
    <tr>
      @for (column of columns; track column.key) {
      <td *ngIf="!column.hidden" [class]="column.getColumnClass('body-td')">
        <p-skeleton class="w-full"></p-skeleton>
      </td>
      }
    </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr *ngIf="!loading">
      <td colspan="8">{{ emptyTableMessage }}</td>
    </tr>
    @if (loading) {
    <tr>
      @for (column of columns; track column.key) {
      <td *ngIf="!column.hidden" [class]="column.getColumnClass('body-td')">
        <p-skeleton class="w-full"></p-skeleton>
      </td>
      }
    </tr>
    }
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-rowData>
    <ng-container
      *ngIf="isExpandable(rowData)"
      [ngTemplateOutlet]="getRowExpansionTemplate()"
      [ngTemplateOutletContext]="{
        $implicit: rowData,
        rowData: rowData,
        value: rowData,
        templates: templates
      }"
    ></ng-container>
  </ng-template>

  <ng-template
    #defaultCellTemplate
    pTemplate="default"
    type="ms-cell"
    let-rowData="rowData"
    let-column="column"
    let-value="value"
  >
    @if (column.options?.preserveCase) {
    <div innerHTML="{{ value || column.options?.defaultValue }}"></div>
    } @else {
    <div
      innerHTML="{{ value || column.options?.defaultValue | titlecase }}"
    ></div>
    }
  </ng-template>

  <ng-template
    #dateValueTemplate
    pTemplate="dateValueTemplate"
    type="ms-cell"
    let-rowData="rowData"
    let-column="column"
    let-value="value"
  >
    <div>{{ value | date: "MM/dd/yyyy" }}</div>
  </ng-template>

  <ng-template
    #costValueTemplate
    pTemplate="costValueTemplate"
    type="ms-cell"
    let-rowData="rowData"
    let-column="column"
    let-value="value"
  >
    <div>{{ value | currency: "USD" }}</div>
  </ng-template>

  <ng-template
    #numberTemplate
    pTemplate="numberTemplate"
    type="ms-cell"
    let-rowData="rowData"
    let-column="column"
    let-value="value"
  >
    <div class="text-right">{{ value | number: "1.0-2" }}</div>
  </ng-template>

  <ng-template
    #percentTemplate
    pTemplate="percentTemplate"
    type="ms-cell"
    let-rowData="rowData"
    let-column="column"
    let-value="value"
  >
    <div class="text-right">
      {{ value ? (value | number: "1.0-2") + " %" : "" }}
    </div>
  </ng-template>
</p-table>
