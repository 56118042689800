import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Column, TableAction, TableItem } from '../models';
import { MenuItem } from 'primeng/api';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ms-action-column',
  templateUrl: './action-column.component.html',
  styleUrl: './action-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionColumnComponent implements OnInit {
  @Input() column: Column;
  @Input() row: TableItem;

  @Output() action = new EventEmitter<{ item: TableItem; action: string }>();

  menuItems: MenuItem[] = [];

  faEllipsisV = faEllipsisV;

  constructor() {}

  ngOnInit() {
    for (let action of this.column.actions) {
      for (let menuItem of action.menuItems ?? []) {
        if (menuItem.isVisible == null || menuItem.isVisible(this.row)) {
          this.menuItems.push({
            ...menuItem,
            ...this.row,
            command: () => this.onAction(this.row, menuItem.action),
          });
        }
      }
    }
  }

  onAction(item: TableItem, action: string) {
    this.action.next({ item, action });
  }

  isDisabledMenu(action: TableAction) {
    return !(
      action?.menuItems &&
      (!action.isVisible || action.isVisible(this.row)) &&
      action.menuItems?.some(
        (item) => !item.isVisible || item.isVisible(this.row)
      )
    );
  }
}
