import { ApiSearchSortDirections } from '@ms/angular-core';

export interface Grid {}

export interface TableEvent {
  pageSize: number;
  currentPage: number;
  filters: any[];
  sortField?: string | undefined;
  sortDirection?: ApiSearchSortDirections;
}

export interface TableActionEvent {
  action: string;
  items: TableItem;
  isSelectedOnAllPages?: boolean;
}

export interface IExpandableOptions {
  hideValue?: boolean;
}

export interface IColumnOptions extends IFrozenColumnOptions {
  sortable: boolean;
  template: string;
  hidden?: boolean;
  sortField?: string;
  tooltip?: string;
  defaultValue?: string;
  preserveCase?: boolean;
}

type AlignColumns = 'left' | 'right';

export interface IFrozenColumnOptions {
  frozen: boolean;
  alignFrozen: AlignColumns;
  expandableOptions?: IExpandableOptions;
  isDisabled?(tableData: TableItem): boolean;
}

const DEFAULT_FROZEN_COLUMN_OPTIONS = {
  frozen: false,
  alignFrozen: <AlignColumns>'left',
  expandableOptions: {
    hideValue: false,
  },
};

const DEFAULT_COLUMN_OPTIONS = {
  sortable: false,
  hidden: false,
  template: 'default',
  ...DEFAULT_FROZEN_COLUMN_OPTIONS,
};

export interface TableActionMenuItem {
  label: string;
  action: string;
  icon?: string;
  isVisible?(tableData: TableItem): boolean;
  visibleInHeader?: boolean;
}

export interface TableAction {
  visibleInHeader?: boolean;
  type: 'menu' | 'button' | 'iconButton';
  icon?: string;
  label?: string;
  action: string;
  menuItems?: TableActionMenuItem[];
  tooltip?: string;

  isVisible?(appraiser: TableItem): boolean;
}

export class Column {
  public options: IColumnOptions = DEFAULT_COLUMN_OPTIONS;
  protected type: 'select' | 'data' | 'actions' | 'expand';

  constructor(
    private _key: string,
    private _label: string | number | boolean,
    options: Partial<IColumnOptions> = {}
  ) {
    this.options = { ...DEFAULT_COLUMN_OPTIONS, ...options };
  }

  protected _actions: TableAction[];

  get actions() {
    return this._actions;
  }

  get key(): string {
    return this._key;
  }

  get label() {
    return this._label;
  }

  get tooltip() {
    return this.options.tooltip;
  }

  get sortable() {
    return this.options.sortable;
  }

  get sortKey() {
    return this.options.sortField || this.key;
  }

  get frozen() {
    return this.options.frozen;
  }

  get alignFrozen() {
    return this.options.alignFrozen;
  }

  get hidden() {
    return this.options.hidden;
  }

  get hideExpandableValue() {
    return this.options.expandableOptions?.hideValue ?? false;
  }

  get isSelect() {
    return this.type === 'select';
  }

  get isData(): boolean {
    return this.type === 'data';
  }

  get isExpand() {
    return this.type === 'expand';
  }

  isActions(rowData: TableItem): boolean {
    return (
      this.type === 'actions' &&
      this.actions &&
      this.actions?.some(
        (item) =>
          !item.isVisible ||
          item.isVisible(rowData) ||
          !item.menuItems ||
          item.menuItems?.some(
            (action) => !action.isVisible || action.isVisible(rowData)
          )
      )
    );
  }

  getColumnClass(el: string) {
    return `ms-${el}-${this.key.replace('.', '-')}`;
  }
}

export class DataColumn extends Column {
  public type = <const>'data';
}

export class ExpandColumn extends Column {
  public type = <const>'expand';

  constructor(options: Partial<IFrozenColumnOptions> = {}) {
    super('expand', '', options);
  }
}

export class SelectColumn extends Column {
  public type = <const>'select';

  constructor(options: Partial<IFrozenColumnOptions> = {}) {
    super('select', '', options);
  }
}

export class ActionsColumn extends Column {
  public type = <const>'actions';

  constructor(
    label: string,
    protected _actions: TableAction[],
    options: Partial<IFrozenColumnOptions> = {}
  ) {
    super('actions', label, options);
  }
}

export type TableItem = any;

export interface TableData {
  items: TableItem[];
  totalItemCount: number;
  itemCount: number;
  sortField: string | undefined;
  sortDirection: ApiSearchSortDirections.Ascending;
}
